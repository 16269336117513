import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

// Simple controller to toggle custom door builder favorites
export default class extends Controller {
  static values = {
    url: String,
    builder: String,
    question: String,
    option: String,
  }

  connect() {
  }
  toggle(event) {
    event.preventDefault()

    // parse params or object and encodes/escapes 
    let params = new URLSearchParams()
    params.append("builder", this.builderValue) 
    params.append("key", this.questionValue) //key: question_tag
    params.append("value", this.optionValue) //value: option

    patch(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }
}