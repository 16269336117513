/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("jquery");
require("popper.js");

window.Cookies = require("js-cookie");
require("bootstrap");
require("bootstrap-datepicker");

import "controllers"
import "channels"
import "@hotwired/turbo-rails"
import "@fortawesome/fontawesome-pro/js/all"
import "./front/custom"

import "../stylesheets/application.scss"

window.jQuery = $;
window.$ = $;