export function serialize(form, params) {
  if (!form) {
    return;
  }
  var i, j = [];
  for (i = form.elements.length - 1; i >= 0; i = i - 1) {
    if (form.elements[i].name === "") {
      continue;
    }
    switch (form.elements[i].nodeName) {
      case 'INPUT':
        switch (form.elements[i].type) {
        case 'text':
        case 'number':
        case 'hidden':
        case 'password':
        case 'button':
        case 'reset':
        case 'submit':
            params.append(form.elements[i].name, encodeURIComponent(form.elements[i].value));
            break;
        case 'checkbox':
        case 'radio':
            if (form.elements[i].checked) {
              params.append(form.elements[i].name, encodeURIComponent(form.elements[i].value));
            }
            break;
        }
        break;
      case 'file':
        break;
      case 'TEXTAREA':
        params.append(form.elements[i].name, encodeURIComponent(form.elements[i].value));
        break;
      case 'SELECT':
        switch (form.elements[i].type) {
        case 'select-one':
            params.append(form.elements[i].name, encodeURIComponent(form.elements[i].value));
            break;
        case 'select-multiple':
            for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
                if (form.elements[i].options[j].selected) {
                    params.append(form.elements[i].name, encodeURIComponent(form.elements[i].options[j].value));
                }
            }
            break;
        }
        break;
      case 'BUTTON':
        switch (form.elements[i].type) {
        case 'reset':
        case 'submit':
        case 'button':
            params.append(form.elements[i].name, encodeURIComponent(form.elements[i].value));
            break;
        }
        break;
    }
  }
}