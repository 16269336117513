import { Controller } from "@hotwired/stimulus"
import { put } from "@rails/request.js"
import Sortable from "sortablejs"

// Simple controller
export default class extends Controller {
  static values = { 
    url: String
  };

  connect() {
    this.elementsSortable(this.element)
  }

  elementsSortable(element){
    let stimulusElement = this 
    let container = element 

    element.sortable = Sortable.create(container, {
      animation: 150,
      draggable: ".draggable",
      forceFallback: true,
      fallbackAxis: "y",
      dragoverBubble: true,
      handle: ".drag-handle",
      onEnd: function(event){
        const id = event.item.dataset["sortedItem"];
        const url = stimulusElement.urlValue.replace(":id", id);
        const newPosition = event.newIndex + 1;
        put(url, {
          body: JSON.stringify({ position: newPosition }),
        });
      }
    });
  }
}