import { Controller } from "@hotwired/stimulus"

// Simple controller to toggle hideable elements on the customer quotes screen.
export default class extends Controller {
  static targets = [ "hideable", "button", "enableable" ]
  static values = {details: Boolean}

  connect() {
    this.updateHiddenClass()
    this.updateEnabledClass()
    this.updateText()
  }

  flip() {
    this.flipState()
    this.updateHiddenClass()
    this.updateEnabledClass()
    this.updateText()
  }

  flipState() {
    this.detailsValue = !this.detailsValue
  }

  updateHiddenClass() {
    this.hideableTargets.forEach((target) => {
      target.hidden = !this.detailsValue
    });
  }

  updateEnabledClass() {
    this.enableableTargets.forEach((target) => {
      target.classList.toggle("disabled")
    });
  }

  newText() {
    return this.detailsValue ? "<i class='far fa-check'></i> Details Visible" : "<i class='far fa-times'></i> Details Visible"
  }

  updateText() {
    this.buttonTargets.forEach((target) => {
      target.innerHTML = this.newText()
    });
  }
}
