import { Controller } from "@hotwired/stimulus"

// Simple controller's click action just clicks any elements marked as a “click” target.
// Clear action clears targeted form elements
export default class extends Controller {
  static targets = [ "click", "clear" ]

  click() {
    this.clickTargets.forEach(target => target.click())
  }

  clear() {
    event.preventDefault()
    this.clearTargets.forEach(target => target.value = "")
  }
}