import { Controller } from "@hotwired/stimulus"

// Simple controller to check if all of the form fields that are 'required' fields
// have entries. Enables the cart send button when valid.
export default class extends Controller {
  static targets = ["sendBtn"];
  static values = {
    formId: String
  }

  change(event) {
    let isValid = true
    let requiredFieldSelectors = 'input:required'
    let form = document.getElementById(this.formIdValue)
    let requiredFields = form.querySelectorAll(requiredFieldSelectors)

    requiredFields.forEach((field) => {
      if (!field.disabled) {
        // For each required field, check to see if the value is empty
        if (field.type === 'checkbox') {
          if (field.checked === false) {
             isValid = false
          }
        } else if (!field.value.trim()) {
          isValid = false;
        }
      }
    })

    this.sendBtnTargets.forEach((target) => {
      if (isValid) {
        target.removeAttribute("disabled")
      } else {
        target.setAttribute("disabled", "disabled") 
      }
    })
    return isValid;
  }
}