import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { serialize } from "../modules/form_fields"

export default class extends Controller {
  static values = {
    url: String,
    param: String,
    page: String
  }

  change(event) {
    // parse params or object and encodes/escapes  
    let params = new URLSearchParams()

    //params.append(this.paramValue, event.target.value)  //'unit', 'Single Door'
    params.append("page", this.pageValue)                //'target', 'height'

    let form = document.getElementById("builderForm");
    serialize(form, params);

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }
}
