$(document).ready(function() {
  /* enable tooltips site-wide */
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]'
  });

  $('.toast').toast('show');

  $('.left-slide-panel').on('show.bs.modal', function(event) {
    $('.left-slide-panel').removeClass('slideOutLeft').addClass('slideInLeft');
  });
  $('.left-slide-panel').on('hide.bs.modal', function(event) {
    $('.left-slide-panel').removeClass('slideInLeft').addClass('slideOutLeft');
  });
  $('.right-slide-panel').on('show.bs.modal', function(event) {
    $('.right-slide-panel').removeClass('slideOutRight').addClass('slideInRight');
  });
  $('.right-slide-panel').on('hide.bs.modal', function(event) {
    $('.right-slide-panel').removeClass('slideInRight').addClass('slideOutRight');
  });
  
  $('.datepicker').datepicker();
});