import { Application } from "@hotwired/stimulus"
import { StreamActions } from "@hotwired/turbo"

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

export { application }

// Simple custom action to scroll to a target
// Ex. turbo_stream_action_tag "scroll_to", target: @cart_item
StreamActions.scroll_to = function() {
  this.targetElements[0].scrollIntoView({behavior: 'smooth'})
}